import '../css/header.css';
import ig from '../assets/bg_hdr_oma.png';
const Header = () =>{
    return (
        <header className="head-wrapper">
          {/* style={{background: `url(${ig})`}} */}
          <div className='header container-fluid' >
            <a title="Southern California Gas Company, A Sempra Energy utility. Link goes to the homepage." className='header__logo-link' href="http://www.socalgas.com/"> 
          <img className="header__logo-link__logo"   src='/SCG_Logo.png' alt="Southern California Gas Company: A Sempra Energy Utility"/>
          </a>
           
          </div>
        </header>
      )

}

export default Header