import "../css/stop-service.css";
import { useEffect, useState, useRef } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import userEvent from "@testing-library/user-event";
const StreetFilter = ({
  onStreetAddressChange,
  onCityChange,
  onZipChange,
  onStateChange,
  serviceType,
  updateFormData,
}) => {
  const inputRef = useRef(null);
  // CIS cities lookup table
  const cisCities = [
    "ARROWBEAR",
    "ARROWBEAR LAKE",
    "AUBERRY",
    "ACTON",
    "ADELANTO",
    "AGOURA",
    "ARROYO GRANDE",
    "AGUANGA",
    "AGOURA HILLS",
    "AHWAHNEE",
    "ALBERHILL",
    "ALHAMBRA",
    "ALISO VIEJO",
    "ALPAUGH",
    "ALPINE",
    "ALTADENA",
    "ALTA LOMA",
    "AMBOY",
    "ANAHEIM",
    "ANZA",
    "ANGELUS OAKS",
    "ARCADIA",
    "ARLETA",
    "ARMONA",
    "ARTESIA",
    "ARVIN",
    "ATASCADERO",
    "ATWOOD",
    "APPLE VALLEY",
    "AVALON",
    "AVILA BEACH",
    "AVENAL",
    "AZUSA",
    "BALLARD",
    "BANNING",
    "BARD",
    "BIG BEAR CITY",
    "BIG BEAR LAKE",
    "BELL CANYON",
    "BERMUDA DUNES",
    "BADGER",
    "BEAUMONT",
    "BELLFLOWER",
    "BELL",
    "BENTON",
    "BRADBURY ESTATES",
    "BETTERAVIA",
    "BELL GARDENS",
    "BEVERLY HILLS",
    "BIG CREEK",
    "BIG PINE",
    "BIOLA",
    "BALBOA ISLAND",
    "BLUE JAY",
    "BAKERSFIELD",
    "BAKER",
    "BLOOMINGTON",
    "BALBOA",
    "BASS LAKE",
    "BLYTHE",
    "BRYN MAWR",
    "BODFISH",
    "BONITA",
    "BONSALL",
    "BORREGO SPRINGS",
    "BOULEVARD",
    "BUENA PARK",
    "BALDWIN PARK",
    "BRIDGEPORT",
    "BRADBURY",
    "BRANDEIS",
    "BRADLEY",
    "BREA",
    "BRAWLEY",
    "BORON",
    "BISHOP",
    "BARSTOW",
    "BUTTONWILLOW",
    "BUELLTON",
    "BURBANK",
    "BURRELL",
    "CABAZON",
    "CALIFORNIA CITY",
    "CADIZ",
    "CALABASAS",
    "CALIMESA",
    "CALIPATRIA",
    "CALEXICO",
    "CAMARILLO",
    "CAMBRIA",
    "CAMPO",
    "CANTIL",
    "CARDIFF",
    "CARLSBAD",
    "CARPINTERIA",
    "CARSON",
    "CARUTHERS",
    "CASTAIC",
    "CATHEDRAL CITY",
    "CAYUCOS",
    "CANYON COUNTRY",
    "CANTUA CREEK",
    "CORONA DEL MAR",
    "CERRITOS",
    "CEDAR GLEN",
    "COARSEGOLD",
    "CHINO",
    "CHATSWORTH",
    "CHINO HILLS",
    "CHARTER OAK",
    "CHOLAME",
    "CHOWCHILLA",
    "CALIFORNIA HOT SPRINGS",
    "CHULA VISTA",
    "CIMA",
    "CLAREMONT",
    "CALIENTE",
    "CLOVIS",
    "COSTA MESA",
    "CAMP NELSON",
    "CAMP ROBERTS",
    "CANYON LAKE",
    "COACHELLA",
    "COALINGA",
    "COLTON",
    "CITY OF COMMERCE",
    "COMPTON",
    "CORONA",
    "CORCORAN",
    "CORONADO",
    "COVINA",
    "CANOGA PARK",
    "CAPISTRANO BEACH",
    "CEDARPINES PARK",
    "CRESTON",
    "CREST PARK",
    "CRESTLINE",
    "CASMALIA",
    "CASITAS SPRINGS",
    "CUDAHY",
    "CULVER CITY",
    "CUTLER",
    "CUYAMA",
    "COWAN HEIGHTS",
    "CYPRESS",
    "COTO DE CAZA",
    "DAGGETT",
    "DIAMOND BAR",
    "DESERT CENTER",
    "DOVE CANYON",
    "DESCANSO",
    "DEVILS DEN",
    "DOMINGUEZ HILLS",
    "DESERT HOT SPRINGS",
    "DI GIORGIO",
    "DINUBA",
    "DUNLAP",
    "DEL MAR",
    "DELANO",
    "DEL REY",
    "DOWNEY",
    "DOS PALOS",
    "DANA POINT",
    "DARWIN",
    "DUARTE",
    "DUCOR",
    "DULZURA",
    "DEATH VALLEY",
    "EARP",
    "ENCINITAS",
    "EDISON",
    "EDWARDS",
    "EAST HIGHLAND",
    "EL CENTRO",
    "EL CAJON",
    "EL MONTE",
    "EL MODENA",
    "EARLIMART",
    "EL SEGUNDO",
    "LAKE ELSINORE",
    "EL TORO",
    "ENCINO",
    "ESCONDIDO",
    "ESSEX",
    "EASTVALE",
    "EASTVIEW",
    "EXETER",
    "FALLBROOK",
    "FARMERSVILLE",
    "FAWNSKIN",
    "FOREST FALLS",
    "FORT IRWIN",
    "FILLMORE",
    "FIREBAUGH",
    "FISH CAMP",
    "FELLOWS",
    "FONTANA",
    "FRESNO",
    "FRIANT",
    "FRAZIER PARK",
    "FOUNTAIN VALLEY",
    "FOOTHILL RANCH",
    "FULLERTON",
    "FOWLER",
    "GARDENA",
    "GAVIOTA",
    "GARDEN GROVE",
    "GRANADA HILLS",
    "GILMAN HOT SPRINGS",
    "GLENDORA",
    "GLENDALE",
    "GLENNVILLE",
    "GOLETA",
    "GOSHEN",
    "GRAPEVINE",
    "GROVER BEACH",
    "GORMAN",
    "GRAND TERRACE",
    "GUASTI",
    "GUADALUPE",
    "GUATAY",
    "GREEN VALLEY LAKE",
    "HALCYON",
    "HARBOR CITY",
    "HARMONY",
    "HAWTHORNE",
    "HERMOSA BEACH",
    "HACIENDA HEIGHTS",
    "HEBER",
    "HELM",
    "HELENDALE",
    "HEMET",
    "HESPERIA",
    "HANFORD",
    "HAWAIIAN GARDENS",
    "HIGHLAND",
    "HIDDEN HILLS",
    "HUNTINGTON LAKE",
    "HOLLYWOOD",
    "HINKLEY",
    "HOLTVILLE",
    "HOMELAND",
    "HUNTINGTON PARK",
    "HUNTINGTON BEACH",
    "HUME",
    "HURON",
    "IDYLLWILD",
    "IMPERIAL",
    "IMPERIAL BEACH",
    "CITY OF INDUSTRY",
    "INDIO",
    "INDEPENDENCE",
    "INGLEWOOD",
    "INYOKERN",
    "IRVINE",
    "IRWINDALE",
    "IVANHOE",
    "INDIAN WELLS",
    "JACUMBA",
    "JAMUL",
    "JOHANNESBURG",
    "JUNE LAKE",
    "JURUPA VALLEY",
    "JOSHUA TREE",
    "JULIAN",
    "KAWEAH",
    "KINGS CANYON NATIONAL PARK",
    "KEELER",
    "KEENE",
    "KELSO",
    "KERMAN",
    "KETTLEMAN CTY",
    "KERN CITY",
    "KERNVILLE",
    "KINGSBURG",
    "LOS ANGELES",
    "LA CANADA FLINTRIDGE",
    "LA CRESCENTA",
    "LAGUNA BEACH",
    "LA JOLLA",
    "LAKESIDE",
    "LOS ALAMITOS",
    "LA MESA",
    "LANCASTER",
    "LANDERS",
    "LLANO",
    "LA PALMA",
    "LA QUINTA",
    "LATON",
    "LONG BEACH",
    "LEBEC",
    "LOS BANOS",
    "LEMON COVE",
    "LUDLOW",
    "LINDSAY",
    "LEE VINING",
    "LEMOORE",
    "LEMON GROVE",
    "LAKE HUGHES",
    "LA HABRA",
    "LA HABRA HEIGHTS",
    "LAGUNA HILLS",
    "LINCOLN ACRES",
    "LAKE ARROWHEAD",
    "LAKE FOREST",
    "LAKE ISABELLA",
    "LAKEVIEW",
    "LAKEWOOD",
    "LOMA LINDA",
    "LITTLE LAKE",
    "LA MIRADA",
    "LAMONT",
    "LOS NIETOS",
    "LAGUNA NIGUEL",
    "LOMITA",
    "LOMPOC",
    "LAKE OF THE WOODS",
    "LONE PINE",
    "LA PUENTE",
    "LADERA RANCH",
    "EL RIO",
    "LOS ALAMOS",
    "LOST HILLS",
    "LAKESHORE",
    "LOS OLIVOS",
    "LOS OSOS",
    "LITTLEROCK",
    "LA VERNE",
    "LUCERNE VALLEY",
    "LAGUNA WOODS",
    "LAWNDALE",
    "LYNWOOD",
    "LYTLE CREEK",
    "MARCH AFB",
    "MALIBU",
    "MAYWOOD",
    "MANHATTAN BEACH",
    "MORRO BAY",
    "MOUNTAIN CENTER",
    "MC FARLAND",
    "MC KITTRICK",
    "MARICOPA",
    "MARINA DEL REY",
    "MADERA",
    "MIDWAY CITY",
    "MECCA",
    "MENDOTA",
    "MENTONE",
    "METTLER",
    "MISSION HILLS",
    "MONO HOT SPRINGS",
    "MIDLAND",
    "MIRA LOMA",
    "MOUNT LAGUNA",
    "MONOLITH",
    "MAMMOTH LAKES",
    "MENIFEE",
    "MEINERS OAKS",
    "MOJAVE",
    "MONTECITO",
    "MONARCH BEACH",
    "MONROVIA",
    "MONTROSE",
    "MORENO VALLEY",
    "MOUNT WILSON",
    "MOUNTAIN PASS",
    "MONTEREY PARK",
    "MIRALESTE",
    "MIRAMONTE",
    "MOORPARK",
    "MT BALDY",
    "MONTEBELLO",
    "MONTCLAIR",
    "MONTALVO",
    "MURRIETA",
    "MORONGO VALLEY",
    "MISSION VIEJO",
    "NATIONAL CITY",
    "NEWPORT BEACH",
    "NEWPORT COAST",
    "NEW CUYAMA",
    "NEEDLES",
    "NEWBERRY SPRINGS",
    "NEWHALL",
    "NORTH FORK",
    "NORTH HOLLYWOOD",
    "NORTH HILLS",
    "NIPOMO",
    "NIPTON",
    "NILAND",
    "NEW LONDON",
    "NORCO",
    "NEWBURY PARK",
    "NORTH PALM SPRINGS",
    "NORTHRIDGE",
    "NORTH TUSTIN",
    "NUEVO",
    "NORWALK",
    "OAKHURST",
    "OAK PARK",
    "OAK VIEW",
    "OCEANSIDE",
    "OCEANO",
    "OCOTILLO",
    "ORANGE COVE",
    "OJAI",
    "OAK HILLS",
    "OLANCHA",
    "OLIVE",
    "O NEALS",
    "ONTARIO",
    "ONYX",
    "ORCUTT",
    "ORANGE",
    "ORO GRANDE",
    "OROSI",
    "OXNARD",
    "PACOIMA",
    "PALA",
    "PALMDALE",
    "PALOMAR MOUNTAIN",
    "PARKER DAM",
    "PASADENA",
    "PASO ROBLES",
    "PAUMA VALLEY",
    "PORTUGUESE BEND",
    "PANORAMA CITY",
    "PALM DESERT",
    "PLAYA DEL REY",
    "PEARBLOSSOM",
    "PERRIS",
    "PHELAN",
    "PORTOLA HILLS",
    "PICO RIVERA",
    "PIEDRA",
    "PINON HILLS",
    "PINE VALLEY",
    "PINON PINE",
    "PIRU",
    "PIXLEY",
    "PLACENTIA",
    "PLASTER CITY",
    "PT MUGU NAWC",
    "PARAMOUNT",
    "POMONA",
    "POND",
    "POPLAR",
    "POSEY",
    "POTRERO",
    "POWAY",
    "PACIFIC PALISADES",
    "PRATHER",
    "PARLIER",
    "PRS STRMRE",
    "PALM SPRINGS",
    "PISMO BEACH",
    "PORT HUENEME",
    "PATTON",
    "PORTERVILLE",
    "PIONEERTOWN",
    "PALOS VERDES",
    "PALO VERDE",
    "PALOS VERDES ESTATES",
    "PALOS VERDES PENINSULA",
    "QUARTZ HILL",
    "QUAIL VALLEY",
    "RAISIN CITY",
    "RAMONA",
    "RANCHITA",
    "RANDSBURG",
    "RAYMOND",
    "REDONDO BEACH",
    "RANCHO CUCAMONGA",
    "REEDLEY",
    "RED MOUNTAIN",
    "REDLANDS",
    "RESEDA",
    "ROLLING HILLS ESTATES",
    "RICHGROVE",
    "RIDGECREST",
    "RIMFOREST",
    "RIVERSIDE",
    "RIVERDALE",
    "RIALTO",
    "RANCHO MIRAGE",
    "RANCHO DOMINGUEZ",
    "ROLLING HILLS",
    "ROMOLAND",
    "ROSAMOND",
    "RIPLEY",
    "RANCHO PALOS VERDES",
    "RUNNING SPRINGS",
    "RANCHO SANTA FE",
    "RANCHO SANTA MARGARITA",
    "ROSEMEAD",
    "ROSSMOOR",
    "ROWLAND HEIGHTS",
    "SANTA ANA",
    "SANTA ANA HEIGHTS",
    "SANGER",
    "SANTEE",
    "SAN YSIDRO",
    "SAN ARDO",
    "SATICOY",
    "SAUGUS",
    "SANTA YSABEL",
    "SANTA BARBARA",
    "SAN BERNARDINO",
    "STUDIO CITY",
    "SAN CLEMENTE",
    "SANTA CLARITA",
    "SAN MARCOS",
    "SALTON CITY",
    "SAN DIMAS",
    "SAN DIEGO",
    "SOUTH DOS PALOS",
    "SEELEY",
    "SOUTH EL MONTE",
    "SEPULVEDA",
    "SAN FERNANDO",
    "SANTA FE SPRINGS",
    "SAN GABRIEL",
    "SOUTH GATE",
    "SIGNAL HILL",
    "SHANDON",
    "SHAFTER",
    "SHELL BEACH",
    "SHOSHONE",
    "SILVERADO",
    "SIMI VALLEY",
    "SISQUOC",
    "SAN JOAQUIN",
    "SAN JUAN CAPISTRANO",
    "SKY FOREST",
    "SOUTH LAGUNA",
    "SEAL BEACH",
    "SHAVER LAKE",
    "SELMA",
    "SAN LUIS OBISPO",
    "SAN LUIS REY",
    "SANTA MARIA",
    "SAN MARINO",
    "SIERRA MADRE",
    "SANTA MONICA",
    "SUN CITY",
    "SAN JACINTO",
    "SAN MIGUEL",
    "SEQUOIA NATIONAL PARK",
    "SAN PEDRO",
    "SHERMAN OAKS",
    "SOLANA BEACH",
    "SOLVANG",
    "SOMIS",
    "SANTA PAULA",
    "SOUTH PASADENA",
    "SPRING VALLEY",
    "SPRINGVILLE",
    "SQUAW VALLEY",
    "SANTA RITA PARK",
    "SOUTH SAN GABRIEL",
    "SAN SIMEON",
    "STARCREST",
    "STRATFORD",
    "SANTA MARGARITA",
    "STRATHMORE",
    "STANTON",
    "STEVENSON RANCH",
    "SUGARLOAF",
    "SULTANA",
    "SUMMERLAND",
    "SUNSET BEACH",
    "SUNLAND",
    "SURFSIDE",
    "SUN VALLEY",
    "SUN VILLAGE",
    "SANTA YNEZ",
    "SYLMAR",
    "TAFT",
    "TARZANA",
    "RANCHO MISSION VIEJO",
    "TEMPLE CITY",
    "TRABUCO CANYON",
    "TECATE",
    "TECOPA",
    "TEMECULA",
    "TEMPLETON",
    "TERRA BELLA",
    "TERMINAL ISLAND",
    "TEVISTON",
    "THERMAL",
    "TEHACHAPI",
    "TIPTON",
    "TOLUCA LAKE",
    "THOUSAND OAKS",
    "TOLLHOUSE",
    "TOPANGA",
    "TORRANCE",
    "TWIN PEAKS",
    "TRANQUILLITY",
    "TRAVER",
    "TRONA",
    "TUJUNGA",
    "TULARE",
    "TUPMAN",
    "TUSTIN",
    "UNIVERSAL CITY",
    "UPLAND",
    "VANDENBERG AFB",
    "VALENCIA",
    "VALYERMO",
    "VERDUGO CITY",
    "VALLEY CENTER",
    "VIDAL",
    "VENICE",
    "VENTURA",
    "VERNON",
    "VISALIA",
    "VISTA",
    "VALINDA",
    "VAN NUYS",
    "VILLA PARK",
    "VALLEY VILLAGE",
    "VICTORVILLE",
    "WALNUT",
    "WARNER SPRINGS",
    "WASCO",
    "WAUKENA",
    "WESTCHESTER",
    "WEST COVINA",
    "WOODLAKE",
    "WOODVILLE",
    "WELDON",
    "WESTMORLAND",
    "WOODLAND HILLS",
    "WHITTIER",
    "WHEELER RIDGE",
    "WEST HOLLYWOOD",
    "WILMINGTON",
    "WINNETKA",
    "WINTERHAVEN",
    "WISHON",
    "WESTLAKE VILLAGE",
    "WILDOMAR",
    "WINCHESTER",
    "WOFFORD HEIGHTS",
    "WOODY",
    "WALNUT PARK",
    "WESTMINSTER",
    "WEST HILLS",
    "WHITE WATER",
    "WRIGHTWOOD",
    "YERMO",
    "YETTEM",
    "YORBA LINDA",
    "YUCAIPA",
    "YUCCA VALLEY",
    "THOUSAND PALMS",
    "TWENTYNINE PALMS",
    "THREE RIVERS",
    "FIVE POINTS",
  ];
  // Capture input changes
  const handleInputChange = () => {
    const inputValue = inputRef.current.value; // Get the current input value
    updateFormData({ streetAddress: inputValue });
    console.log("Current input value:", inputValue);
    // You can also store this value in a state if needed
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handler
  const debouncedInputChange = debounce(() => {
    handleInputChange(inputRef.current.value);
  }, 300); // Adjust delay as needed
  useEffect(() => {
    if (window.google && inputRef.current) {
      // autosuggest widget here, 322 km is approximately 200 miles.  Let's try 200 miles radius from center of SoCal.
      const socalCenter = { lat: 34, lng: -117 };
      const defaultBounds = {
        north: socalCenter.lat + 3.22,
        south: socalCenter.lat - 3.22,
        east: socalCenter.lng + 3.22,
        west: socalCenter.lng - 3.22,
      };
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: "us" },
        fields: ["address_components"],
        strictBounds: false,
        types: ["address"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      // Add event listeners
      inputRef.current.addEventListener("input", debouncedInputChange);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        console.log("Selected Place:", place);
        if (place.address_components) {
          fillInAddress(place, serviceType);
        }
      });
    }
  }, []);

  const fillInAddress = (place) => {
    const componentForm = ["street_address", "city", "state", "zip_code"];
    const fieldToGoogleTypeMap = new Map([
      ["street_address", "no_mapping"],
      ["city", "locality"],
      ["state", "administrative_area_level_1"],
      ["zip_code", "postal_code"],
    ]);

    const addressNameFormat = {
      street_number: "short_name",
      route: "long_name", // street name and direction
      locality: "long_name",
      neighborhood: "long_name",
      administrative_area_level_1: "short_name",
      postal_code: "short_name",
    };
    const getAddressComp = (type) => {
      for (const component of place.address_components) {
        if (component.types[0] === type) {
          return component[addressNameFormat[type]];
        }
      }
      return "";
    };

    // If user selects a non-California address on Start Service, we clear all the address fields except state.
    //   User must select a California address from the suggestion to proceed.
    //   To test, use "2509 Lake Mist Dr", which is an address in Texa, Georgia, and California.
    const abbrState = getAddressComp("administrative_area_level_1");
    if (abbrState !== "CA" && serviceType.type === "Start Service") {
      // clear street, city, and zip
      document.getElementById("street_address").value = "";
      document.getElementById("city").value = "";
      document.getElementById("zip_code").value = "";
      return;
    }

    const streetAddress =
      getAddressComp("street_number") + " " + getAddressComp("route");
    updateFormData({ streetAddress: streetAddress });
    //document.getElementById("street_address").value = streetAddress;
    //updateFormData({streetAddress});
    //onStreetAddressChange(streetAddress);

    const zipCode = getAddressComp(fieldToGoogleTypeMap.get("zip_code"));
    //document.getElementById("zip_code").value = zipCode;
    updateFormData({ zipCode });
    //onZipChange(zipCode);
    /*for (const component of componentForm) {
      // Location field and state are handled separately above and below.
      if (
        component !== "street_address" &&
        component !== "city" &&
        component !== "state"
      ) {
        document.getElementById(component).value = getAddressComp(
          fieldToGoogleTypeMap.get(component)
        )
      }
    }*/
    const city = getAddressComp("locality");
    console.log("city :: ", city);
    //document.getElementById("city").value = city;
    const neighborhood = getAddressComp("neighborhood");
    // If CIS considers the community name as a city, then let's use it instead of the official city.
    if (neighborhood && cisCities.includes(neighborhood.toUpperCase())) {
      console.log("city with neighborhood :: ", city);
      document.getElementById("city").value = city;
    }
    updateFormData({ city });
    //onCityChange(city);
    // Select the state
    // const abbrState = getAddressComp("administrative_area_level_1")
    //document.getElementById("state").val = abbrState;
    updateFormData({ state: abbrState });
    //onStateChange(abbrState);
  };

  const handleChange = (e) => {
    console.log("street address onchange :: ", e.target.value);
    updateFormData({ streetAddress: e.target.value });
    //onStreetAddressChange(e.target.value);
  };

  return (
    <div className="panel-label-input row">
      <div className="col-md-4">
        <label htmlFor="in_care_of" className="">
          <span className="text-danger">*</span>Street Address:
        </label>
      </div>
      <div className="pannel-input street-address-wrapper col-md-8">
        <input
          name=""
          id="street_address"
          className=""
          type="text"
          maxLength={30}
          autoComplete="off"
          placeholder="Enter a location"
          ref={inputRef}
          // onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default StreetFilter;
