import '../css/footer.css';
const Footer = (props) => {
    return (
        <footer className="container-fluid footer-wrapper d-flex flex-column flex-grow-0 flex-shrink-0">
            {/* <div className="container">
                <div className="row text-center">
                    <div className="col-sm-12 col-md-4 col-12 item">
                        <a href={"#"}>Help Center</a>

                    </div>
                    <div className="col-sm-12 col-md-4 col-12 item">
                        <a href={"#"}>SoCalGas Terms and Conditions</a>

                    </div>
                    <div className="col-sm-12 col-md-4 col-12 item">
                        <a href={"#"}>Privacy</a>

                    </div>

                </div>
            </div>
            <div className="container my-4">
                <div className='row'>
                    <div className="col-6 text-center">
                        <img className="header__logo-link__logo" src={require('../images/SCG_Logo.png')} alt="Southern California Gas Company: A Sempra Energy Utility" />

                    </div>
                    <div className="col-6 text-center">
                        <img className="header__logo-link__logo" src={require('../images/digicert')} alt="Southern California Gas Company: A Sempra Energy Utility" />

                    </div>
                </div>
            </div > */}
            <div className="footer-copyright">
                <p className="px-3 mb-3">&#169; 1998 - { new Date().getFullYear() } Southern California Gas Company.</p>
                <p className="px-3 m-0">The trademarks used herein are the property of their respective owners. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer