import { Routes, Route, Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ServiceConfig from "./components/ServiceConfig";
import "./App.css";
import SpinnerContext from "./context/spinnerContext";
import Spinner from "./components/spinner";
import Service from "./components/Service";
import { SuccessPage } from "./components/Appointment";
import { useState } from "react";
//import './css/application.css';

function App() {
  const [loading, setLoading] = useState(false);
  return (
    // <div className="App">
    //   <Header />

    //   <Link to="/service?serviceType=startService"></Link>
    //   <Link to="/service?serviceType=stopService"></Link>
    //   <Link to="/service?serviceType=scheduleAppointment"></Link>
    //   <Routes>
    //     <Route path="" element={<Service />}> </Route>
    //     <Route path="/service" element={<ServiceConfig />}> </Route>
    //     {/* <Route path="/SuccessPage" element={<SuccessPage />} /> */}
    //   </Routes>
    //   <Footer />
    // </div>

    <div className="App">
      {loading && <Spinner />}
      <Header />
      <SpinnerContext.Provider value={setLoading}>
      <Routes>
        <Route path="" element={<Service />}>
          {" "}
        </Route>
       
          <Route
            path="/services/:serviceType/:tokenId"
            element={<ServiceConfig />}
          />
       
        {/* <Route path="/service" element={<ServiceConfig />}> </Route> */}
        {/* <Route path="/SuccessPage" element={<SuccessPage />} /> */}
      </Routes>
      </SpinnerContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
