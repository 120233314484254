import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
// import '../css/stop-service.css';
import "../css/appointmentSample.css";
import "../css/appointmentCalendar.css";
import "../css/cso.css";
const csoAvailabilitySchedAsJsonString = `
[
  {
    "SchdDayDt": "2022-11-21",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-22",
    "AvailAllDay": "Y",
    "AvailAm": "N",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-23",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-24",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-25",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-26",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-27",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-28",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-29",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-11-30",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-01",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-02",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-03",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-04",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-05",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-06",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-07",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-08",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-09",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-10",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-11",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-12",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-13",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-14",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-15",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-16",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-17",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-18",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-19",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-20",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  },
  {
    "SchdDayDt": "2022-12-21",
    "AvailAllDay": "Y",
    "AvailAm": "Y",
    "AvailPm": "Y",
    "AvailEveningWnd": "Y",
    "AvailTa": "Y"
  }
]
`;
const csoAvailabilitySchedArr = JSON.parse(csoAvailabilitySchedAsJsonString);
console.log("There are " + csoAvailabilitySchedArr.length + " available date.");

function getWeekdayFirstHalf(aDateStr) {
  const STR_FIRST_HALF_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const aDate = new Date(aDateStr);
  const dayOfWeekIndex = aDate.getDay();
  return STR_FIRST_HALF_DAYS[dayOfWeekIndex];
}

function getWeekdaySecondHalf(aDateStr) {
  const STR_LAST_HALF_DAYS = [
    "day",
    "day",
    "sday",
    "nesday",
    "rsday",
    "day",
    "urday",
  ];
  const aDate = new Date(aDateStr);
  const dayOfWeekIndex = aDate.getDay();
  return STR_LAST_HALF_DAYS[dayOfWeekIndex];
}

function getMonthAndDate(aDateStr) {
  const STR_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const aDate = new Date(aDateStr);
  const monthIndex = aDate.getMonth();
  const monthName = STR_MONTHS[monthIndex];
  const dayOfMonth = aDate.getDate();
  return `, ${monthName} ${dayOfMonth}`;
}

function FreeAppointmentCalendar({
  setSelectedAppointments,
  a2FoodIndustry = "",
}) {
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const captureSelectedDate = (slot, dateStr) => {
    // console.log("In captureSelectedDate", slot, dateStr)
    let cellPhoneNumber = "",
      termsAndCondition = "",
      technician_in = "";

    setSelectedSlot(slot);
    setSelectedDate(dateStr);
    setSelectedAppointments({
      slot,
      dateStr,
      cellPhoneNumber,
      termsAndCondition,
    });
  };

  const wrappedLeftArrowClickCB = (e) => {
    e.preventDefault();
    window["leftArrowClickCB"]();
  };

  const wrappedRightArrowClickCB = (e) => {
    e.preventDefault();
    window["rightArrowClickCB"]();
  };
  return (
    <div
      id="apt-calendar"
      className="apt-calendar-widget apt-calendar"
      role="application"
      aria-live="polite"
    >
      <table style={{ borderCollapse: "collapse" }}>
        <tbody>
          <tr>
            <td>
              <a
                className="apt-calendar-left-arrow"
                onClick={wrappedLeftArrowClickCB}
              >
                <span className="arrow-left display-none"></span>
              </a>
            </td>
            <td className="apt-calendar-dates-cell" valign="top">
              <div className="apt-calendar-dates-container">
                <table
                  role="grid"
                  cellPadding="0"
                  cellSpacing="0"
                  className="apt-calendar-dates-table"
                  style={{ left: 0, height: "98%" }}
                >
                  <colgroup>
                    {csoAvailabilitySchedArr.map((d) => (
                      <col key={d.SchdDayDt} style={{ width: 102 }} />
                    ))}
                  </colgroup>
                  <thead>
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((d) => (
                        <th key={d.SchdDayDt} role="columnheader">
                          {getWeekdayFirstHalf(d.SchdDayDt)}
                          <span className="spokenOnly">
                            {getWeekdaySecondHalf(d.SchdDayDt)}
                          </span>
                          {getMonthAndDate(d.SchdDayDt)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* All Day slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.SchdDayDt}
                          role="gridcell"
                          className={
                            v.SchdDayDt === selectedDate && selectedSlot === "N"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectAllDay facet */}
                          {(v.AvailAllDay === "Y" ||
                            (a2FoodIndustry === "4hour" && i === 0)) && (
                            <a
                              onClick={() =>
                                captureSelectedDate("N", v.SchdDayDt)
                              }
                            >
                              {a2FoodIndustry === "4hour" && i === 0 && (
                                <span>Within 4 Hours</span>
                              )}
                              {(a2FoodIndustry !== "4hour" || i !== 0) && (
                                <span>
                                  All day:
                                  <br />7 am - 8 pm
                                </span>
                              )}
                            </a>
                          )}

                          {/* ADNotApplicable facet */}
                          {v.AvailAllDay === "N" &&
                            !(a2FoodIndustry === "4hour" && i === 0) && (
                              <span>
                                <span className="fs-14">N/A</span>
                                <span className="spokenOnly">
                                  {getWeekdayFirstHalf(v.SchdDayDt) +
                                    getWeekdaySecondHalf(v.SchdDayDt) +
                                    getMonthAndDate(v.SchdDayDt) +
                                    " is not available."}
                                </span>
                              </span>
                            )}
                        </td>
                      ))}
                    </tr>
                    {/* AM slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.SchdDayDt}
                          role="gridcell"
                          className={
                            v.SchdDayDt === selectedDate && selectedSlot === "1"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* select7AM facet */}
                          {v.AvailAm === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("1", v.SchdDayDt)
                              }
                            >
                              7 am - Noon
                            </a>
                          )}
                          {/* 7AMNotApplicable facet */}
                          {v.AvailAm === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.SchdDayDt) +
                                  getWeekdaySecondHalf(v.SchdDayDt) +
                                  getMonthAndDate(v.SchdDayDt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                    {/* PM slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.SchdDayDt}
                          role="gridcell"
                          className={
                            v.SchdDayDt === selectedDate && selectedSlot === "2"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectPM facet*/}
                          {v.AvailPm === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("2", v.SchdDayDt)
                              }
                            >
                              Noon - 5 pm
                            </a>
                          )}
                          {/* PMNotApplicable facet */}
                          {v.AvailPm === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.SchdDayDt) +
                                  getWeekdaySecondHalf(v.SchdDayDt) +
                                  getMonthAndDate(v.SchdDayDt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                    {/* Evening slot */}
                    <tr
                      role="row"
                      style={
                        a2FoodIndustry === "hideNARow" ||
                        a2FoodIndustry === "4hour"
                          ? { display: "none" }
                          : {}
                      }
                    >
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.SchdDayDt}
                          role="gridcell"
                          className={
                            v.SchdDayDt === selectedDate && selectedSlot === "3"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectEvng facet */}
                          {v.AvailEveningWnd === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("3", v.SchdDayDt)
                              }
                            >
                              5 pm - 8 pm
                            </a>
                          )}
                          {/* EvngNotApplicable facet */}
                          {v.AvailEveningWnd === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.SchdDayDt) +
                                  getWeekdaySecondHalf(v.SchdDayDt) +
                                  getMonthAndDate(v.SchdDayDt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td>
              <a
                className="apt-calendar-right-arrow"
                onClick={wrappedRightArrowClickCB}
              >
                <span className="arrow-right display-none"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function SuccessPage() {
  return (
    <section
      id="success_message_section"
      className="success-page content-separator d-flex justify-content-center align-items-center  p-4 m-3"
      style={{ background: "white", height: "34rem" }}
    >
      <div className="message-container">
        <div className="d-flex justify-content-center align-items-center">
          <img
            title="Success"
            alt="Success"
            className="message-icon"
            src="/icon_green_checkmark.png"
          />
        </div>
        <div className="success-text-panel d-flex flex-column justify-content-center align-items-center">
          <h1 className="header-text">Success!</h1>
          <p id="success_desc_text">
            We have received your forwarding address. Thank you!
          </p>
        </div>
      </div>
    </section>
  );
}

function ErrorPage() {
  return (
    <section
      id="backend_error_message_section"
      className="content-separator d-none"
    >
      <div className="message-container">
        <img
          title="Error"
          alt="Error"
          className="message-icon"
          src={"/icon_red_attention.png"}
        />
        <p>
          We are unable to process your request at this time. Please stay on the
          call and we will continue through the automated phone system.
        </p>
      </div>
    </section>
  );
}

function App() {
  const navigate = useNavigate();
  const [selectedAppointment, setSelectedAppointment] = useState({
    selectedAppointmentType: "",
    dateStr: "",
    slot: "",
    cellPhoneNumber: "",
    termsAndCondition: "",
  });

  const [showSuccessPage, setShowSuccessPage] = useState(false);
  window.addEventListener("beforeunload", function (e) {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  });
  useEffect(() => {
    setTimeout(function () {
      window["apptCalMultiInit"]();
    }, 2000);
  }, []);

  const getSelectedAppointmentDisplay = () => {
    const STR_DAYS = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const STR_MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const { dateStr, slot } = selectedAppointment;
    const aDate = new Date(dateStr);
    const dayOfWeek = STR_DAYS[aDate.getDay()];
    const shortMonthName = STR_MONTHS[aDate.getMonth()];
    const dayOfMonth = aDate.getDate();
    const year = aDate.getFullYear();
    let slotDesc = "";
    if (slot === "1") {
      slotDesc = "7 am - Noon";
    } else if (slot === "2") {
      slotDesc = "Noon - 5 pm";
    } else if (slot === "3") {
      slotDesc = "5 pm - 8 pm";
    } else if (slot === "4") {
      slotDesc = "Within 4 Hours";
    } else {
      // N
      slotDesc = "7 am - 8 pm";
    }
    return `${dayOfWeek}, ${shortMonthName} ${dayOfMonth}, ${year}, ${slotDesc}`;
  };

  function handleChange(event) {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setSelectedAppointment({
      ...selectedAppointment,
      [event.target.name]: value,
    });
  }
  const handleSubmit = (e) => {
    console.log(`Submit :: ${JSON.stringify(selectedAppointment)}`);
    e.preventDefault();

    setShowSuccessPage(true);
  };

  //const handleSubmit = () => location.href="https://www.tutorix.com"

  return (
    <>
      <main
        className={`d-flex flex-column main ${
          showSuccessPage ? "d-none" : "d-block"
        }`}
      >
        <section className="main__content-wrapper content-separator">
          <section className="main__content">
            <section className=" flex-grow-0 main__page-title ">
              <p className="main__page-title__name">
                Schedule A Service Appointment
              </p>
            </section>
            <p className="line-seperator"></p>
            <section className="flex-grow-0 user-detail">
              <form
                id="calendar_form"
                name="calendar_form"
                className="custom-form"
                action="here_you_go.action"
              >
                {/* <Availability/> */}
                <FreeAppointmentCalendar
                  setSelectedAppointments={setSelectedAppointment}
                />
                {/* Selected Appointment */}

                <div className="selected-appointment-date">
                  <label className="selected-appointment-date__label">
                    Your selected appointment is:
                  </label>
                  <span className="selected-appointment-date__text">
                    {selectedAppointment.dateStr &&
                      selectedAppointment.slot && (
                        <span className="selAppt">
                          &nbsp;&nbsp;
                          {getSelectedAppointmentDisplay()}
                        </span>
                      )}
                  </span>
                </div>

                <p className="line-seperator"></p>

                {/* CellPhone Number */}

                <section>
                  <section className="cell-phone-number row">
                    <label
                      htmlFor="in_care_of"
                      className="cell-phone-number__label col-12 text-start label col-form-label"
                    >
                      <span style={{ color: "red" }}>*</span>Cellphone Number:
                    </label>

                    <span className="cell-phone-number__input col-12">
                      <input
                        name="cellPhoneNumber"
                        id="in_care_of"
                        className="form-input country-name-text"
                        type="text"
                        onChange={handleChange}
                      />
                    </span>
                  </section>

                  <p className="gray-bg p-2 cell-phone-number__hint country-name-hint">
                    Please use the following format XXX-XXX-XXXX The cellphone
                    number you provide must belong to the account holder.
                  </p>
                </section>

                {/* Hint */}
                <div className="d-flex gap-2 p-2 cellphone-number-info">
                  <img
                    className="cellphone-number-info__img"
                    alt="info"
                    src="/pngwing.com.png"
                  />
                  {/* <InfoRoundedIcon style={{ color: "#004B90" }} /> */}
                  <p className="mb-0">
                    Your account cellphone number can be used to receive
                    important account information and alerts, such as service
                    appointments and reminders. We do not sell or distribute
                    your information to third parties.
                  </p>
                </div>

                {/* Terms and Condition */}

                <div className="d-flex gap-2 mb-3 terms-and-condition">
                  <input
                    className="terms-and-condition__checkBox "
                    type="checkbox"
                    name="termsAndCondition"
                    value="True"
                    id="flexCheckDefault"
                    onChange={handleChange}
                  />
                  {/* <InfoRoundedIcon style={{ color: "#004B90" }} /> */}

                  <label
                    className="terms-and-condition__label  form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <b>I decline to provide a cellphone number at this time.</b>{" "}
                    I understand that I will have to contact Customer Service to
                    reset my password.
                  </label>
                </div>

                <p className="line-seperator"></p>
                {/* <span>{JSON.stringify(selectedAppointment) }</span> */}
                {/* <span>test : {(selectedAppointment.dateStr != "" && (selectedAppointment.cellPhoneNumber != "" || selectedAppointment.termsAndCondition != "")) ? false : true}</span> */}
                <div className="submit-btn d-flex flex-row-reverse mt-2">
                  <button
                    type="button"
                    id="next_button"
                    className="btn-orange"
                    disabled={
                      selectedAppointment.dateStr !== "" &&
                      (selectedAppointment.cellPhoneNumber !== "" ||
                        selectedAppointment.termsAndCondition !== false)
                        ? false
                        : true
                    }
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                <input type="hidden" id="tokenId" name="tokenId" />
              </form>
            </section>
          </section>
        </section>
      </main>

      {/* SUCCESS PAGE */}
      <main className={`${showSuccessPage ? "d-block" : "d-none"}`}>
        <SuccessPage />
      </main>
    </>
  );
}

export default App;
