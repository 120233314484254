import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "../css/stop-service.css";
import { useEffect, useState, useRef } from "react";
import StreetFilter from "./StreetFilter";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import spinnerContext from "../context/spinnerContext";

const Service = (props) => {
  const formURL = process.env.REACT_APP_START_SERVICE_SUBMIT_API;
  const showSpinner = useContext(spinnerContext);
  const countryRef = useRef(null);
  const provinceRef = useRef(null);
  const stateRef = useRef(null);
  const errorListRef = useRef(null);
  const errorSectionRef = useRef(null);
  const [tokenID, setTokenID] = useState("");
  const [startPath, setStartPath] = useState("");

  useEffect(() => {
    // Parse the query parameters using URLSearchParams
    //const params = new URLSearchParams(window.location.search);
    const token = props.serviceInfo.tokenId;
    const path = window.location.pathname;
    const mappedPath = path.split("/");
    setTokenID(token);
    setStartPath(mappedPath[2]);
  }, []);
  const [formData, setFormData] = useState({
    tokenId: "",
    serviceType: "",
    inCareOf: "",
    streetAddress: "",
    unitNumber: "",
    city: "",
    state: "CA",
    zipCode: "",
    country: "United States",
    provinceOrRegion: "",
  });

   // create a function that the child component can call
  const updateFormData = (obj) => {
    console.log("updateFormData :: ", obj);
    
    setFormData(prevFormData => {
      console.log("Previous formData :: ", prevFormData);
      return {
        ...prevFormData,
        ...obj
      };
    });
  };

  const [errorArray, setErrorArray] = useState([]);
  const [errorLinkArray, setErrorLinkArray] = useState([]);

  const clearAndHideErrorSection = () => {
    setErrorArray([]);
    setErrorLinkArray([]);
    if (errorListRef.current) {
      // Clear all <li> elements by setting innerHTML to an empty string
      errorListRef.current.innerHTML = "";
    }

    if (
      errorSectionRef.current &&
      !errorSectionRef.current.classList.contains("d-none")
    ) {
      errorSectionRef.current.classList.add("d-none");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "country") {
      if (value !== "United States") {
        //state
        document.getElementById("us_state_row").classList.add("d-none");
        //province
        document
          .getElementById("non_us_province_row")
          .classList.remove("d-none");
      } else {
        //state
        document.getElementById("us_state_row").classList.remove("d-none");

        //province
        document.getElementById("non_us_province_row").classList.add("d-none");
        if (provinceRef.current) {
          provinceRef.current.value = "";
        }
      }
    }
  };

  const handleStreetAddressChange = (streetAddress) => {
    console.log("streetAddress :: ", formData);
    setFormData({
      ...formData,
      country : countryRef.current?.value ?? "",
      state: stateRef.current?.value ?? "",
      streetAddress: streetAddress,
    });
  };

  useEffect(() => {
    console.log("Updated formData after state change::", formData);
  }, [formData]); // This will log whenever formData is updated

  const handleCityChange = (city) => {
    console.log("city :: ", formData);
    setFormData((prevData) => ({
      ...prevData,
      city,
    }));
  };

  const handleZipCodeChange = (zipCode) => {
    console.log("zipCode :: ", formData);
    
    setFormData((prevData) => ({
      ...prevData,
      zipCode: zipCode,
    }));
  };

  const handleStateChange = (state) => {
    console.log("state :: ", formData);
    setFormData((prevData) => ({
      ...prevData,
      state,
    }));
  };

  const handleSubmit = (e) => {
    console.log("error check :: ", formData);
    e.preventDefault();
    clearAndHideErrorSection();
    //console.log("Form data :: ", formData);

    if (formData.inCareOf !== "") {
      const inCareOfPattern = /^[^\d]*$/;
      if (!inCareOfPattern.test(formData.inCareOf)) {
        errorArray.push(
          '<li>Please enter <a id="in_care_of_link" href="#">in care of</a> without numbers.</li>'
        );
        errorLinkArray.push({
          linkId: "in_care_of_link",
          fieldId: "in_care_of",
        });
      }
    }

    if (formData.streetAddress.trim() === "") {
      errorArray.push(
        '<li>Please enter your <a id="street_address_link" href="#">street address</a>.</li>'
      );
      errorLinkArray.push({
        linkId: "street_address_link",
        fieldId: "street_address",
      });
    }

    if (formData.city.trim() === "") {
      errorArray.push(
        '<li>Please enter your <a id="city_link" href="#">city</a>.</li>'
      );
      errorLinkArray.push({ linkId: "city_link", fieldId: "city" });
    } else {
      const cityPattern = /^[^\d]*$/;
      if (!cityPattern.test(formData.city)) {
        errorArray.push(
          '<li>Please enter <a id="city_link" href="#">city</a> without numbers.</li>'
        );
        errorLinkArray.push({ linkId: "city_link", fieldId: "city" });
      }
    }

    if (
      formData.country !== "United States" &&
      formData.provinceOrRegion.trim() === "" 
    ) {
      errorArray.push(
        '<li>Please enter your <a id="province_or_region_link" href="#">province or region</a>.</li>'
      );
      errorLinkArray.push({
        linkId: "province_or_region_link",
        fieldId: "province_or_region",
      });
    }

    if (formData.country === "United States") {
      const zipCodePattern = /^\d{5}$/;
      if (!zipCodePattern.test(formData.zipCode.trim())) {
        errorArray.push(
          '<li>Please enter your <a id="zip_code_link" href="#">5-digit zip code</a>.</li>'
        );
        errorLinkArray.push({ linkId: "zip_code_link", fieldId: "zip_code" });
      }
    } else {
      if (formData.zipCode.length < 1 || formData.zipCode.length > 10) {
        errorArray.push(
          '<li>Please enter a valid <a id="zip_code_link" href="#">zip / postal code</a>.</li>'
        );
        errorLinkArray.push({ linkId: "zip_code_link", fieldId: "zip_code" });
      }
    }
    //console.log(errorLinkArray);

    if (errorArray.length > 0) {
      showErrorSection(errorArray);
      registerErrorLinkHandlers(errorLinkArray);
    } else {
      postFormData();
    }
  };

  const showErrorSection = (errorArray) => {
    const errorList = document.getElementById("error_list");
    errorList.innerHTML = "";
    errorArray.forEach((error) => {
      errorList.innerHTML += error;
    });
    document.getElementById("error_section").classList.remove("d-none");
  };

  const registerErrorLinkHandlers = (errorLinkArray) => {
    errorLinkArray.forEach(({ linkId, fieldId }) => {
      const link = document.getElementById(linkId);
      const field = document.getElementById(fieldId);
      link.addEventListener("click", () => {
        field.focus();
      });
    });
  };

  const postFormData = () => {
    // Handle form submission
    document.getElementById("#next_button")?.setAttribute("disabled");
    showSpinner(true);
    // instantiate a headers object
    let myHeaders = new Headers();
    // add content type header to object
    myHeaders.append("Content-Type", "application/json");

    // create a JSON object with parameters for API call and store in a variable
    formData.tokenId = tokenID;
    formData.serviceType = startPath;
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
      redirect: "follow",
      credentials: "same-origin", // or 'same-origin'
    };
    console.log(requestOptions);
    // make API call with parameters and use promises to get response
    // sample API Gateway REST URL:  https://d3nfn5zlrx0q6a.cloudfront.net/q/res
    fetch(formURL, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        // console.log("response from api :: ", json);
        showSpinner(false);
        if (json.respCode == 0) {
          //alert("Success");
          // show the success section and hide the rest
          try {
            document
              .querySelector("#success_message_section")
              .classList.remove("d-none");
          } catch (e) {
            console.error("success_message_section", e);
          }

          try {
            document
              .querySelector("#backend_error_message_section")
              .classList.add("d-none");
          } catch (e) {
            console.error("backend_error_message_section", e);
          }

          try {
            document.querySelector("#error_section").classList.add("d-none");
          } catch (e) {
            console.error("error_section", e);
          }

          try {
            document
              .querySelector("#page_title_and_content_section")
              .classList.add("d-none");
          } catch (e) {
            console.error("page_title_and_content_section", e);
          }
        } else {
          //alert("Error from respCode");
          // show backend error section and hide the rest
          document
            .getElementById("success_message_section")
            .classList.add("d-none");
          document
            .getElementById("backend_error_message_section")
            .classList.remove("d-none");
          document.getElementById("error_section").classList.add("d-none");

          try {
            document
              .querySelector("#page_title_and_content_section")
              .classList.add("d-none");
          } catch (e) {
            console.error("page_title_and_content_section", e);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        showSpinner(false);
        alert("Error from plumbing:  " + error);
      });
  };

  return (
    <>
      <Helmet>
        <title>{props.serviceInfo.pageTitle}</title>
      </Helmet>
      <main className="d-flex flex-column flex-grow-0 flex-shrink-0 main">
        <div className="section-wrapper">
          <section
            id="success_message_section"
            className="content-wrapper content-separator d-none"
          >
            <div className="message-container">
              <img
                title="Success"
                alt="Success"
                className="message-icon"
                src="/icon_green_checkmark.png"
              />
              <div className="success-text-panel">
                <h1 className="header-text">Success!</h1>
                <p id="success_desc_text">
                  We have received your forwarding address. Thank you!
                </p>
              </div>
            </div>
          </section>
          <section
            id="backend_error_message_section"
            className="content-wrapper content-separator d-none"
          >
            <div className="message-container">
              <img
                title="Error"
                alt="Error"
                className="message-icon"
                src="/icon_red_attention.png"
              />
              <p>
                We are unable to process your request at this time. Please stay
                on the call and we will continue through the automated phone
                system.
              </p>
            </div>
          </section>
          {/* The error section is initially not displayed.  It's displayed when user selects Next while having not filled the required fields.  */}
          <section
            id="error_section"
            className="content-wrapper content-separator d-none"
            ref={errorSectionRef}
          >
            <div className="error-list-container message-container">
              <img
                title="Error"
                alt="Error"
                className="message-icon"
                src="/icon_red_attention.png"
              />
              <div className="error-text-panel">
                <h1 className="header-text">
                  Please fix the following errors:
                </h1>
                <ul id="error_list" ref={errorListRef}>
                  <li>
                    Please enter your <a href="#">street address</a>.
                  </li>
                  <li>
                    Please enter your <a href="#">city</a>.
                  </li>
                  <li>
                    Please enter your <a href="#">5-digit zip code</a>.
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section
            className="main__content-wrapper content-separator"
            id="page_title_and_content_section"
          >
            <section className="main__content">
              <section className=" flex-grow-0 main__page-title">
                <p className="main__page-title__name fw-bold">
                  {props.serviceInfo.title}
                </p>
                <div className="main__page-title__description row">
                  <div className="main__page-title__description__content col-md-10">
                    Please enter the address for your final bill.
                  </div>
                  <div className="main__page-title__description__required text-danger col-md-2">
                    *Required
                  </div>
                </div>
              </section>
              <p className="line-seperator"></p>
              <section className="flex-grow-0 user-detail">
                <form className="user-detail__form" onSubmit={handleSubmit}>
                  {props.serviceInfo.hideInputfields ? (
                    <div
                      id="care_of_row"
                      className="user-detail__form__care-of-row row panel-label-input"
                    >
                      <div className="col-md-4">
                        <label htmlFor="in_care_of" className="label">
                          In Care Of:
                        </label>
                      </div>
                      <div className="pannel-input col-md-8">
                        <input
                          name="inCareOf"
                          id="in_care_of"
                          className=""
                          type="text"
                          value={formData.inCareOf}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  ) : null}

                  {props.serviceInfo.hideInputfields ? (
                    <div
                      id="country"
                      className="user-detail__form__country row panel-label-input"
                    >
                      <div className="col-md-4">
                        <label htmlFor="country" className="label">
                          <span className="text-danger">*</span>Country:
                        </label>
                      </div>
                      <div className="pannel-input col-md-8">
                        <select
                          id="country"
                          className=""
                          name="country"
                          defaultValue="United States"
                          ref = {countryRef}
                          value={formData.country}
                          onChange={handleChange}
                        >
                          <option value="United States" title="United States">
                            United States
                          </option>
                          <option value="Afghanistan" title="Afghanistan">
                            Afghanistan
                          </option>
                          <option value="Albania" title="Albania">
                            Albania
                          </option>
                          <option value="Algeria" title="Algeria">
                            Algeria
                          </option>
                          <option value="Andorra" title="Andorra">
                            Andorra
                          </option>
                          <option value="Angola" title="Angola">
                            Angola
                          </option>
                          <option
                            value="Antigua and Barbuda"
                            title="Antigua and Barbuda"
                          >
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina" title="Argentina">
                            Argentina
                          </option>
                          <option value="Armenia" title="Armenia">
                            Armenia
                          </option>
                          <option value="Aruba" title="Aruba">
                            Aruba
                          </option>
                          <option value="Australia" title="Australia">
                            Australia
                          </option>
                          <option value="Austria" title="Austria">
                            Austria
                          </option>
                          <option value="Azerbaijan" title="Azerbaijan">
                            Azerbaijan
                          </option>
                          <option value="Bahamas, The" title="Bahamas, The">
                            Bahamas, The
                          </option>
                          <option value="Bahrain" title="Bahrain">
                            Bahrain
                          </option>
                          <option value="Bangladesh" title="Bangladesh">
                            Bangladesh
                          </option>
                          <option value="Barbados" title="Barbados">
                            Barbados
                          </option>
                          <option value="Belarus" title="Belarus">
                            Belarus
                          </option>
                          <option value="Belgium" title="Belgium">
                            Belgium
                          </option>
                          <option value="Belize" title="Belize">
                            Belize
                          </option>
                          <option value="Benin" title="Benin">
                            Benin
                          </option>
                          <option value="Bhutan" title="Bhutan">
                            Bhutan
                          </option>
                          <option value="Bolivia" title="Bolivia">
                            Bolivia
                          </option>
                          <option
                            value="Bosnia and Herzegovina"
                            title="Bosnia and Herzegovina"
                          >
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana" title="Botswana">
                            Botswana
                          </option>
                          <option value="Brazil" title="Brazil">
                            Brazil
                          </option>
                          <option value="Brunei" title="Brunei">
                            Brunei
                          </option>
                          <option value="Bulgaria" title="Bulgaria">
                            Bulgaria
                          </option>
                          <option value="Burkina Faso" title="Burkina Faso">
                            Burkina Faso
                          </option>
                          <option value="Burma" title="Burma">
                            Burma
                          </option>
                          <option value="Burundi" title="Burundi">
                            Burundi
                          </option>
                          <option value="Cambodia" title="Cambodia">
                            Cambodia
                          </option>
                          <option value="Cameroon" title="Cameroon">
                            Cameroon
                          </option>
                          <option value="Canada" title="Canada">
                            Canada
                          </option>
                          <option value="Cape Verde" title="Cape Verde">
                            Cape Verde
                          </option>
                          <option
                            value="Central African Republic"
                            title="Central African Republic"
                          >
                            Central African Republic
                          </option>
                          <option value="Chad" title="Chad">
                            Chad
                          </option>
                          <option value="Chile" title="Chile">
                            Chile
                          </option>
                          <option value="China" title="China">
                            China
                          </option>
                          <option value="Colombia" title="Colombia">
                            Colombia
                          </option>
                          <option value="Comoros" title="Comoros">
                            Comoros
                          </option>
                          <option
                            value="Congo, Democratic Republic of the"
                            title="Congo, Democratic Republic of the"
                          >
                            Congo, Democratic Republic of the
                          </option>
                          <option
                            value="Congo, Republic of the"
                            title="Congo, Republic of the"
                          >
                            Congo, Republic of the
                          </option>
                          <option value="Costa Rica" title="Costa Rica">
                            Costa Rica
                          </option>
                          <option value="Cote d'Ivoire" title="Cote d'Ivoire">
                            Cote d'Ivoire
                          </option>
                          <option value="Croatia" title="Croatia">
                            Croatia
                          </option>
                          <option value="Cuba" title="Cuba">
                            Cuba
                          </option>
                          <option value="Curacao" title="Curacao">
                            Curacao
                          </option>
                          <option value="Cyprus" title="Cyprus">
                            Cyprus
                          </option>
                          <option value="Czech Republic" title="Czech Republic">
                            Czech Republic
                          </option>
                          <option value="Denmark" title="Denmark">
                            Denmark
                          </option>
                          <option value="Djibouti" title="Djibouti">
                            Djibouti
                          </option>
                          <option value="Dominica" title="Dominica">
                            Dominica
                          </option>
                          <option
                            value="Dominican Republic"
                            title="Dominican Republic"
                          >
                            Dominican Republic
                          </option>
                          <option
                            value="East Timor (see Timor-Leste)"
                            title="East Timor (see Timor-Leste)"
                          >
                            East Timor (see Timor-Leste)
                          </option>
                          <option value="Ecuador" title="Ecuador">
                            Ecuador
                          </option>
                          <option value="Egypt" title="Egypt">
                            Egypt
                          </option>
                          <option value="El Salvador" title="El Salvador">
                            El Salvador
                          </option>
                          <option
                            value="Equatorial Guinea"
                            title="Equatorial Guinea"
                          >
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea" title="Eritrea">
                            Eritrea
                          </option>
                          <option value="Estonia" title="Estonia">
                            Estonia
                          </option>
                          <option value="Ethiopia" title="Ethiopia">
                            Ethiopia
                          </option>
                          <option value="Fiji" title="Fiji">
                            Fiji
                          </option>
                          <option value="Finland" title="Finland">
                            Finland
                          </option>
                          <option value="France" title="France">
                            France
                          </option>
                          <option value="Gabon" title="Gabon">
                            Gabon
                          </option>
                          <option value="Gambia, The" title="Gambia, The">
                            Gambia, The
                          </option>
                          <option value="Georgia" title="Georgia">
                            Georgia
                          </option>
                          <option value="Germany" title="Germany">
                            Germany
                          </option>
                          <option value="Ghana" title="Ghana">
                            Ghana
                          </option>
                          <option value="Greece" title="Greece">
                            Greece
                          </option>
                          <option value="Grenada" title="Grenada">
                            Grenada
                          </option>
                          <option value="Guatemala" title="Guatemala">
                            Guatemala
                          </option>
                          <option value="Guinea" title="Guinea">
                            Guinea
                          </option>
                          <option value="Guinea-Bissau" title="Guinea-Bissau">
                            Guinea-Bissau
                          </option>
                          <option value="Guyana" title="Guyana">
                            Guyana
                          </option>
                          <option value="Haiti" title="Haiti">
                            Haiti
                          </option>
                          <option value="Holy See" title="Holy See">
                            Holy See
                          </option>
                          <option value="Honduras" title="Honduras">
                            Honduras
                          </option>
                          <option value="Hong Kong" title="Hong Kong">
                            Hong Kong
                          </option>
                          <option value="Hungary" title="Hungary">
                            Hungary
                          </option>
                          <option value="Iceland" title="Iceland">
                            Iceland
                          </option>
                          <option value="India" title="India">
                            India
                          </option>
                          <option value="Indonesia" title="Indonesia">
                            Indonesia
                          </option>
                          <option value="Iran" title="Iran">
                            Iran
                          </option>
                          <option value="Iraq" title="Iraq">
                            Iraq
                          </option>
                          <option value="Ireland" title="Ireland">
                            Ireland
                          </option>
                          <option value="Israel" title="Israel">
                            Israel
                          </option>
                          <option value="Italy" title="Italy">
                            Italy
                          </option>
                          <option value="Jamaica" title="Jamaica">
                            Jamaica
                          </option>
                          <option value="Japan" title="Japan">
                            Japan
                          </option>
                          <option value="Jordan" title="Jordan">
                            Jordan
                          </option>
                          <option value="Kazakhstan" title="Kazakhstan">
                            Kazakhstan
                          </option>
                          <option value="Kenya" title="Kenya">
                            Kenya
                          </option>
                          <option value="Kiribati" title="Kiribati">
                            Kiribati
                          </option>
                          <option value="Korea, North" title="Korea, North">
                            Korea, North
                          </option>
                          <option value="Korea, South" title="Korea, South">
                            Korea, South
                          </option>
                          <option value="Kosovo" title="Kosovo">
                            Kosovo
                          </option>
                          <option value="Kuwait" title="Kuwait">
                            Kuwait
                          </option>
                          <option value="Kyrgyzstan" title="Kyrgyzstan">
                            Kyrgyzstan
                          </option>
                          <option value="Laos" title="Laos">
                            Laos
                          </option>
                          <option value="Latvia" title="Latvia">
                            Latvia
                          </option>
                          <option value="Lebanon" title="Lebanon">
                            Lebanon
                          </option>
                          <option value="Lesotho" title="Lesotho">
                            Lesotho
                          </option>
                          <option value="Liberia" title="Liberia">
                            Liberia
                          </option>
                          <option value="Libya" title="Libya">
                            Libya
                          </option>
                          <option value="Liechtenstein" title="Liechtenstein">
                            Liechtenstein
                          </option>
                          <option value="Lithuania" title="Lithuania">
                            Lithuania
                          </option>
                          <option value="Luxembourg" title="Luxembourg">
                            Luxembourg
                          </option>
                          <option value="Macau" title="Macau">
                            Macau
                          </option>
                          <option value="Macedonia" title="Macedonia">
                            Macedonia
                          </option>
                          <option value="Madagascar" title="Madagascar">
                            Madagascar
                          </option>
                          <option value="Malawi" title="Malawi">
                            Malawi
                          </option>
                          <option value="Malaysia" title="Malaysia">
                            Malaysia
                          </option>
                          <option value="Maldives" title="Maldives">
                            Maldives
                          </option>
                          <option value="Mali" title="Mali">
                            Mali
                          </option>
                          <option value="Malta" title="Malta">
                            Malta
                          </option>
                          <option
                            value="Marshall Islands"
                            title="Marshall Islands"
                          >
                            Marshall Islands
                          </option>
                          <option value="Mauritania" title="Mauritania">
                            Mauritania
                          </option>
                          <option value="Mauritius" title="Mauritius">
                            Mauritius
                          </option>
                          <option value="Mexico" title="Mexico">
                            Mexico
                          </option>
                          <option value="Micronesia" title="Micronesia">
                            Micronesia
                          </option>
                          <option value="Moldova" title="Moldova">
                            Moldova
                          </option>
                          <option value="Monaco" title="Monaco">
                            Monaco
                          </option>
                          <option value="Mongolia" title="Mongolia">
                            Mongolia
                          </option>
                          <option value="Montenegro" title="Montenegro">
                            Montenegro
                          </option>
                          <option value="Morocco" title="Morocco">
                            Morocco
                          </option>
                          <option value="Mozambique" title="Mozambique">
                            Mozambique
                          </option>
                          <option value="Namibia" title="Namibia">
                            Namibia
                          </option>
                          <option value="Nauru" title="Nauru">
                            Nauru
                          </option>
                          <option value="Nepal" title="Nepal">
                            Nepal
                          </option>
                          <option value="Netherlands" title="Netherlands">
                            Netherlands
                          </option>
                          <option
                            value="Netherlands Antilles"
                            title="Netherlands Antilles"
                          >
                            Netherlands Antilles
                          </option>
                          <option value="New Zealand" title="New Zealand">
                            New Zealand
                          </option>
                          <option value="Nicaragua" title="Nicaragua">
                            Nicaragua
                          </option>
                          <option value="Niger" title="Niger">
                            Niger
                          </option>
                          <option value="Nigeria" title="Nigeria">
                            Nigeria
                          </option>
                          <option value="North Korea" title="North Korea">
                            North Korea
                          </option>
                          <option value="Norway" title="Norway">
                            Norway
                          </option>
                          <option value="Oman" title="Oman">
                            Oman
                          </option>
                          <option value="Pakistan" title="Pakistan">
                            Pakistan
                          </option>
                          <option value="Palau" title="Palau">
                            Palau
                          </option>
                          <option
                            value="Palestinian Territories"
                            title="Palestinian Territories"
                          >
                            Palestinian Territories
                          </option>
                          <option value="Panama" title="Panama">
                            Panama
                          </option>
                          <option
                            value="Papua New Guinea"
                            title="Papua New Guinea"
                          >
                            Papua New Guinea
                          </option>
                          <option value="Paraguay" title="Paraguay">
                            Paraguay
                          </option>
                          <option value="Peru" title="Peru">
                            Peru
                          </option>
                          <option value="Philippines" title="Philippines">
                            Philippines
                          </option>
                          <option value="Poland" title="Poland">
                            Poland
                          </option>
                          <option value="Portugal" title="Portugal">
                            Portugal
                          </option>
                          <option value="Qatar" title="Qatar">
                            Qatar
                          </option>
                          <option value="Romania" title="Romania">
                            Romania
                          </option>
                          <option value="Russia" title="Russia">
                            Russia
                          </option>
                          <option value="Rwanda" title="Rwanda">
                            Rwanda
                          </option>
                          <option
                            value="Saint Kitts and Nevis"
                            title="Saint Kitts and Nevis"
                          >
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia" title="Saint Lucia">
                            Saint Lucia
                          </option>
                          <option
                            value="Saint Vincent and the Grenadines"
                            title="Saint Vincent and the Grenadines"
                          >
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="Samoa" title="Samoa">
                            Samoa
                          </option>
                          <option value="San Marino" title="San Marino">
                            San Marino
                          </option>
                          <option
                            value="Sao Tome and Principe"
                            title="Sao Tome and Principe"
                          >
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia" title="Saudi Arabia">
                            Saudi Arabia
                          </option>
                          <option value="Senegal" title="Senegal">
                            Senegal
                          </option>
                          <option value="Serbia" title="Serbia">
                            Serbia
                          </option>
                          <option value="Seychelles" title="Seychelles">
                            Seychelles
                          </option>
                          <option value="Sierra Leone" title="Sierra Leone">
                            Sierra Leone
                          </option>
                          <option value="Singapore" title="Singapore">
                            Singapore
                          </option>
                          <option value="Sint Maarten" title="Sint Maarten">
                            Sint Maarten
                          </option>
                          <option value="Slovakia" title="Slovakia">
                            Slovakia
                          </option>
                          <option value="Slovenia" title="Slovenia">
                            Slovenia
                          </option>
                          <option
                            value="Solomon Islands"
                            title="Solomon Islands"
                          >
                            Solomon Islands
                          </option>
                          <option value="Somalia" title="Somalia">
                            Somalia
                          </option>
                          <option value="South Africa" title="South Africa">
                            South Africa
                          </option>
                          <option value="South Korea" title="South Korea">
                            South Korea
                          </option>
                          <option value="South Sudan" title="South Sudan">
                            South Sudan
                          </option>
                          <option value="Spain" title="Spain">
                            Spain
                          </option>
                          <option value="Sri Lanka" title="Sri Lanka">
                            Sri Lanka
                          </option>
                          <option value="Sudan" title="Sudan">
                            Sudan
                          </option>
                          <option value="Suriname" title="Suriname">
                            Suriname
                          </option>
                          <option value="Swaziland" title="Swaziland">
                            Swaziland
                          </option>
                          <option value="Sweden" title="Sweden">
                            Sweden
                          </option>
                          <option value="Switzerland" title="Switzerland">
                            Switzerland
                          </option>
                          <option value="Syria" title="Syria">
                            Syria
                          </option>
                          <option value="Taiwan" title="Taiwan">
                            Taiwan
                          </option>
                          <option value="Tajikistan" title="Tajikistan">
                            Tajikistan
                          </option>
                          <option value="Tanzania" title="Tanzania">
                            Tanzania
                          </option>
                          <option value="Thailand" title="Thailand">
                            Thailand
                          </option>
                          <option value="Timor-Leste" title="Timor-Leste">
                            Timor-Leste
                          </option>
                          <option value="Togo" title="Togo">
                            Togo
                          </option>
                          <option value="Tonga" title="Tonga">
                            Tonga
                          </option>
                          <option
                            value="Trinidad and Tobago"
                            title="Trinidad and Tobago"
                          >
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia" title="Tunisia">
                            Tunisia
                          </option>
                          <option value="Turkey" title="Turkey">
                            Turkey
                          </option>
                          <option value="Turkmenistan" title="Turkmenistan">
                            Turkmenistan
                          </option>
                          <option value="Tuvalu" title="Tuvalu">
                            Tuvalu
                          </option>
                          <option value="Uganda" title="Uganda">
                            Uganda
                          </option>
                          <option value="Ukraine" title="Ukraine">
                            Ukraine
                          </option>
                          <option
                            value="United Arab Emirates"
                            title="United Arab Emirates"
                          >
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom" title="United Kingdom">
                            United Kingdom
                          </option>
                          <option value="Uruguay" title="Uruguay">
                            Uruguay
                          </option>
                          <option value="Uzbekistan" title="Uzbekistan">
                            Uzbekistan
                          </option>
                          <option value="Vanuatu" title="Vanuatu">
                            Vanuatu
                          </option>
                          <option value="Venezuela" title="Venezuela">
                            Venezuela
                          </option>
                          <option value="Vietnam" title="Vietnam">
                            Vietnam
                          </option>
                          <option value="Yemen" title="Yemen">
                            Yemen
                          </option>
                          <option value="Zambia" title="Zambia">
                            Zambia
                          </option>
                          <option value="Zimbabwe" title="Zimbabwe">
                            Zimbabwe
                          </option>
                        </select>
                      </div>
                    </div>
                  ) : null}

                  <StreetFilter
                    onStreetAddressChange={handleStreetAddressChange}
                    onCityChange={handleCityChange}
                    onZipChange={handleZipCodeChange}
                    onStateChange={handleStateChange}
                    serviceType={{ type: props.serviceInfo.pageTitle }}
                    updateFormData = {updateFormData}
                  />

                  <div className="panel-label-input row">
                    <div className="col-md-4">
                      <label htmlFor="unit_number" className="">
                        Apartment or Unit Number:
                      </label>
                    </div>
                    <div className="pannel-input col-md-8">
                      <input
                        type="text"
                        id="unit_number"
                        className=""
                        name="unitNumber"
                        maxLength={19}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="panel-label-input row">
                    <div className="col-md-4">
                      <label htmlFor="city" className="">
                        <span className="text-danger">*</span>City:
                      </label>
                    </div>
                    <div className="pannel-input col-md-8">
                      <input
                        type="text"
                        id="city"
                        className=""
                        name="city"
                        maxLength={24}
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div id="us_state_row" className="panel-label-input row">
                    <div className="col-md-4">
                      <label htmlFor="state" className="">
                        <span className="text-danger">*</span>State:
                      </label>
                    </div>
                    <div className="pannel-input col-md-8">
                      <select
                        id="state"
                        className=""
                        name="state"
                        disabled={props.serviceInfo.disabledDropdownElement}
                        defaultValue="CA"
                        onChange={handleChange}
                        ref={stateRef}
                        value={formData.state}
                      >
                        <option value="AL" title="Alabama">
                          Alabama
                        </option>
                        <option value="AK" title="Alaska">
                          Alaska
                        </option>
                        <option value="AZ" title="Arizona">
                          Arizona
                        </option>
                        <option value="AR" title="Arkansas">
                          Arkansas
                        </option>
                        <option value="CA" title="California">
                          California
                        </option>
                        <option value="CO" title="Colorado">
                          Colorado
                        </option>
                        <option value="CT" title="Connecticut">
                          Connecticut
                        </option>
                        <option value="DE" title="Delaware">
                          Delaware
                        </option>
                        <option value="FL" title="Florida">
                          Florida
                        </option>
                        <option value="GA" title="Georgia">
                          Georgia
                        </option>
                        <option value="HI" title="Hawaii">
                          Hawaii
                        </option>
                        <option value="ID" title="Idaho">
                          Idaho
                        </option>
                        <option value="IL" title="Illinois">
                          Illinois
                        </option>
                        <option value="IN" title="Indiana">
                          Indiana
                        </option>
                        <option value="IA" title="Iowa">
                          Iowa
                        </option>
                        <option value="KS" title="Kansas">
                          Kansas
                        </option>
                        <option value="KY" title="Kentucky">
                          Kentucky
                        </option>
                        <option value="LA" title="Louisiana">
                          Louisiana
                        </option>
                        <option value="ME" title="Maine">
                          Maine
                        </option>
                        <option value="MD" title="Maryland">
                          Maryland
                        </option>
                        <option value="MA" title="Massachusetts">
                          Massachusetts
                        </option>
                        <option value="MI" title="Michigan">
                          Michigan
                        </option>
                        <option value="MN" title="Minnesota">
                          Minnesota
                        </option>
                        <option value="MS" title="Mississippi">
                          Mississippi
                        </option>
                        <option value="MO" title="Missouri">
                          Missouri
                        </option>
                        <option value="MT" title="Montana">
                          Montana
                        </option>
                        <option value="NE" title="Nebraska">
                          Nebraska
                        </option>
                        <option value="NV" title="Nevada">
                          Nevada
                        </option>
                        <option value="NH" title="New Hampshire">
                          New Hampshire
                        </option>
                        <option value="NJ" title="New Jersey">
                          New Jersey
                        </option>
                        <option value="NM" title="New Mexico">
                          New Mexico
                        </option>
                        <option value="NY" title="New York">
                          New York
                        </option>
                        <option value="NC" title="North Carolina">
                          North Carolina
                        </option>
                        <option value="ND" title="North Dakota">
                          North Dakota
                        </option>
                        <option value="OH" title="Ohio">
                          Ohio
                        </option>
                        <option value="OK" title="Oklahoma">
                          Oklahoma
                        </option>
                        <option value="OR" title="Oregon">
                          Oregon
                        </option>
                        <option value="PA" title="Pennsylvania">
                          Pennsylvania
                        </option>
                        <option value="RI" title="Rhode Island">
                          Rhode Island
                        </option>
                        <option value="SC" title="South Carolina">
                          South Carolina
                        </option>
                        <option value="SD" title="South Dakota">
                          South Dakota
                        </option>
                        <option value="TN" title="Tennessee">
                          Tennessee
                        </option>
                        <option value="TX" title="Texas">
                          Texas
                        </option>
                        <option value="UT" title="Utah">
                          Utah
                        </option>
                        <option value="VT" title="Vermont">
                          Vermont
                        </option>
                        <option value="VA" title="Virginia">
                          Virginia
                        </option>
                        <option value="WA" title="Washington">
                          Washington
                        </option>
                        <option value="WV" title="West Virginia">
                          West Virginia
                        </option>
                        <option value="WI" title="Wisconsin">
                          Wisconsin
                        </option>
                        <option value="WY" title="Wyoming">
                          Wyoming
                        </option>
                      </select>
                    </div>
                  </div>

                  <div
                    className="panel-label-input d-none row"
                    id="non_us_province_row"
                  >
                    <div className="col-12 col-sm-4 col-md-4 d-flex align-items-center justify-content-start justify-content-sm-end  justify-content-md-end fw-bold">
                      <label htmlFor="province" className="">
                        <span className="text-danger">*</span>Province / Region:
                      </label>
                    </div>
                    <div className="pannel-input col-12 col-sm-6 col-md-5 col-lg-4 col-xxl-4">
                      <input
                        type="text"
                        id="province_or_region"
                        className=""
                        name="provinceOrRegion"
                        maxLength={20}
                        ref={provinceRef}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="panel-label-input row">
                    <div className="col-md-4">
                      <label htmlFor="zip_code" className="">
                        <span className="text-danger">*</span>ZIP / Postal Code:
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        type="text"
                        id="zip_code"
                        className="pannel-input"
                        name="zipCode"
                        maxLength={10}
                        value={formData.zipCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <p className="line-seperator"></p>

                  <div className="d-flex flex-grow-0 flex-row-reverse">
                    <button
                      type="submit"
                      id="next_button"
                      className="btn-orange"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </section>
            </section>
          </section>
        </div>
      </main>
    </>
  );
};

export default Service;
