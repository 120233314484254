import React from 'react';
import '../css/spinner.css';  // Custom spinner CSS

const Spinner = () => (
    <div class="simple-spinner">
    <span></span>
  </div>
);

export default Spinner;
